<template>
  <div class="skeleton-account-view">
    <el-skeleton :animated="animated" :count="count">
      <template slot="template">
        <div class="skeleton-account-view__wrapper">
          <el-skeleton-item variant="h1" />
          <el-skeleton-item variant="p" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      required: false,
      default: true
    },
    count: {
      type: Number,
      default: 3,
      required: false
    }
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .skeleton-account-view {

    .el-skeleton {
      display: flex;
      width: 100%;
      
      &__item {
        height: 22px;
      }

      &__p {
        margin-top: 5px;
        width: 50%;
      }
    }

    &__wrapper {
      height: 182px;
      width: 240px;
      background-color: #fff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      flex: 1;
      padding: 32px 24px;
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>