<template>
  <div class="skeleton-simple-text">
    <el-skeleton :animated="animated" :count="count">
      <template slot="template">
        <div>
          <el-skeleton-item variant="p" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      required: false,
      default: true
    },
    count: {
      type: Number,
      default: 3,
      required: false
    }
  },
}
</script>

<style lang="scss">
  .skeleton-simple-text {
    
    .el-skeleton {
      width: 240px;

      &__item {
        height: 22px;
      }
    }
  }
</style>